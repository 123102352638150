<template>
  <section>
    <div class="w-full mt-2 rounded-md shadow p-2">
      <div class="w-full">
        <button @click="show = !show" class="flex gap-2">
          <i v-if="show" class="pi pi-chevron-down text-blue-800 text-xs"></i>
          <i v-else class="pi pi-chevron-right text-blue-800 text-xs"></i>
          <p class="text-blue-800 font-bold">HISTORIA CLÍNICA</p>
        </button>
      </div>
      <div v-if="show">
        <div>
          <DataTable class="mt-4" :value="dataPacientes" responsiveLayout="scroll">
              <template #empty>
                  Este paciente no cuenta con historia clinica.
              </template>
              <Column headerClass="text-xs" bodyClass="text-xs" header="No. Ov">
                <template #body="{data}">
                    {{ data['No.OV'] }}
                </template>
              </Column>
              <Column headerClass="text-xs" bodyClass="text-xs" header="FechaDcto">
                <template #body="{data}">
                    {{ dayjs(data.FecConta).format('YYYY-MM-DD') }}
                </template>
              </Column>
              <Column headerClass="text-xs" bodyClass="text-xs" header="Fecha entrega">
                <template #body="{data}">
                    {{ data.FechaEntrega ? dayjs(data.FechaEntrega).format('YYYY-MM-DD') : 'Pendiente' }}
                </template>
              </Column>
              <Column headerClass="text-xs" bodyClass="text-xs" header="Cliente">
                <template #body="{data}">
                    {{ data.Cliente }}
                </template>
              </Column>
              <Column headerClass="text-xs" bodyClass="text-xs" header="Mot.Autoriza">
                <template #body="{data}">
                    {{ data['No.Autoriza'] }}
                </template>
              </Column>
              <Column headerClass="text-xs" bodyClass="text-xs" header="Usuario">
                <template #body="{data}">
                    {{ data.UsuarioOVSAP }}
                </template>
              </Column>
              <Column headerClass="text-xs" bodyClass="text-xs" header="Bodega">
                <template #body="{data}">
                    {{ data.Bodega }}
                </template>
              </Column>
              <Column headerClass="text-xs" bodyClass="text-xs" header="Medicamento">
                <template #body="{data}">
                    {{ data['Cod.Mx'] + ' - ' + data.Dscription }}
                </template>
              </Column>
          </DataTable>
          <!-- <Paginator v-model:first="pacientesStore.parametros_paginado_historia_clinica.offset"
            :rows="pacientesStore.parametros_paginado_historia_clinica.limit"
            :totalRecords="pacientesStore._historia_clinica.count"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          /> -->
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(dataPacientes.length)"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { ref, onMounted, watch, computed } from 'vue'
  import { usePacientesStore } from '../../../../stores/pacientes.store'
  import dayjs from 'dayjs'
  export default {
    name: 'inpormacionPaciente',
    setup () {
      const pacientesStore = usePacientesStore()
      const show = ref(true)
      const limit = ref(10)
      const offset = ref(0)
      const pag = ref(0)
      const pageCant = ref(0)
      // const dataPacientes = ref(pacientesStore._historia_clinica)
      const dataPacientes = computed(() => pacientesStore._historia_clinica)
      // const onPage = ({ first, page, pageCount, rows }) => {
      //   pacientesStore.parametros_paginado_historia_clinica.limit = rows
      //   pacientesStore.parametros_paginado_historia_clinica.filtros = { cc: pacientesStore._paciente_seleccionado.U_ACS_NmrIdentf, type_doc: pacientesStore._paciente_seleccionado.U_ACS_TpoIdentf }
      //   pacientesStore.getHitoriaClinicaPaciente()
      // }
      const setPages = () => {
        const indiceInicio = (offset.value - 1) * pageCant.value
        const indiceFin = indiceInicio + pageCant.value
        console.log(pacientesStore._historia_clinica, pageCant, offset.value, pacientesStore._historia_clinica.slice(indiceInicio, indiceFin))
        return pacientesStore._historia_clinica.slice(indiceInicio, indiceFin)
      }
      const onPage = ({ first, page, pageCount, rows }) => {
          console.log(first, page, pageCount, rows)
          limit.value = rows
          offset.value = first
          pag.value = page
          pageCant.value = pageCount
          dataPacientes.value = !dataPacientes.value.length ? pacientesStore._historia_clinica : setPages()
      }
      onMounted(() => {
      })
      watch(pacientesStore._historia_clinica, () => {
        console.log(pacientesStore._historia_clinica)
        dataPacientes.value = pacientesStore._historia_clinica
      })
      return {
        limit,
        offset,
        dataPacientes,
        show,
        dayjs,
        onPage,
        setPages,
        pacientesStore
      }
    }
  }
</script>
<style>
  ::v-deep(.p-inputtext.p-component) {
    font-size: 0.75rem;
    padding: 0.06rem;
  }
  ::v-deep(.p-inputnumber-input) {
    padding: 0.06rem;
  }
  ::v-deep(.p-inputnumber) {
    padding: 0rem;
  }
  ::v-deep(.p-inputnumber-input) {
    padding: 0rem;
  }
</style>
